html, body {
    position: relative;
    width: 100%;
    height: 100%;
}

body {
    color: #333;
    margin: 0;
    padding: 8px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

label {
    display: block;
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    -webkit-padding: 0.4em 0;
    padding: 0.4em;
    margin: 0 0 0.5em 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
}

button {
    color: #333;
    background-color: #f4f4f4;
    outline: none;
}

button:not(:disabled):active {
    background-color: #ddd;
}

button:focus {
    border-color: #666;
}
